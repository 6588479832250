import { pathUrl } from "./path-url";
import {
  UsergroupAddOutlined,
  ApiOutlined,
  DeploymentUnitOutlined,
  SettingOutlined,
  RadarChartOutlined
} from "@ant-design/icons";
import { permissions } from "./permissions";

import store from "../store";


export const isAllow = (permission: any) => {
  if (!Array.isArray(permission)) {
    permission = [permission]
  }
  if (!permission.length) {
    return true;
  }
  let allow = false;
  let state = store.getState();
  let userInfo = state.userReducer.userInfo || {};
  if (!userInfo || userInfo.group != "ADMIN") {
    return false
  }
  if (userInfo && userInfo.isSupper && userInfo.group == "ADMIN") {
    return true;
  }
  let userPermission = state.userReducer.lstPermission || [];
  allow = permission.some((x: any) => {
    if (typeof x === 'object') {
      return Object.keys(x).some((y: any) => userPermission.includes(x[y]))
    }
    return userPermission.includes(x)
  });
  return allow;
}

export const leftMenu = [
  {
    label: "lbl_menu_administrator",
    enable: true,
    permissions: [
      permissions.user,
      permissions.role,
      permissions.permission,
      permissions.role_permission,
    ],
    icon: <UsergroupAddOutlined />,
    children: [
      {
        label: "lbl_menu_user",
        enable: true,
        permissions: [
          permissions.user.admin_user,
          permissions.user.admin_user_add,
          permissions.user.admin_user_edit,
          permissions.user.admin_user_deleted,
          permissions.user.admin_user_import,
          permissions.user.admin_user_export,
        ],
        url: pathUrl.USER.INDEX,
      },
      {
        label: "lbl_menu_role",
        enable: true,
        permissions: [
          permissions.role.admin_role,
          permissions.role.admin_role_add,
          permissions.role.admin_role_edit,
          permissions.role.admin_role_deleted,
          permissions.role.admin_role_import,
          permissions.role.admin_role_export,
        ],
        url: pathUrl.ROLE.INDEX,
      },
      {
        label: "lbl_menu_permission",
        enable: true,
        permissions: [
          permissions.permission.admin_permission,
          permissions.permission.admin_permission_add,
          permissions.permission.admin_permission_edit,
          permissions.permission.admin_permission_deleted,
          permissions.permission.admin_permission_import,
          permissions.permission.admin_permission_export,
        ],
        url: pathUrl.PERMISSION.INDEX,
      },
      {
        label: "lbl_menu_role_permission",
        enable: true,
        permissions: [
          permissions.role_permission.admin_role_permission,
          permissions.role_permission.admin_role_permission_add,
          permissions.role_permission.admin_role_permission_edit,
          permissions.role_permission.admin_role_permission_deleted,
          permissions.role_permission.admin_role_permission_import,
          permissions.role_permission.admin_role_permission_export,
        ],
        url: pathUrl.ROLE_PERMISSION.INDEX,
      }
    ]
  },
  {
    label: "lbl_menu_device",
    enable: true,
    permissions: [permissions.device],
    icon: <ApiOutlined />,
    children: [
      {
        label: "lbl_menu_device",
        enable: true,
        permissions: [
          permissions.device,
          permissions.device.device_add,
          permissions.device.device_edit,
          permissions.device.device_deleted,
          permissions.device.device_import,
          permissions.device.device_export,
        ],
        url: pathUrl.DEVICE.INDEX,
      },
    ]
  },
  {
    label: "lbl_menu_booking",
    enable: true,
    permissions: [
      permissions.merchant,
      permissions.store,
      permissions.booking_object,
      permissions.service
    ],
    icon: <DeploymentUnitOutlined />,
    children: [
      {
        label: "lbl_menu_merchant",
        enable: true,
        permissions: [
          permissions.merchant,
          permissions.merchant.merchant_add,
          permissions.merchant.merchant_edit,
          permissions.merchant.merchant_deleted,
          permissions.merchant.merchant_import,
          permissions.merchant.merchant_export,
        ],
        url: pathUrl.MERCHANT.INDEX,
      },
      {
        label: "lbl_menu_store",
        enable: true,
        permissions: [
          permissions.store,
          permissions.store.store_add,
          permissions.store.store_edit,
          permissions.store.store_deleted,
          permissions.store.store_import,
          permissions.store.store_export,
        ],
        url: pathUrl.STORE.INDEX,
      },
      {
        label: "lbl_menu_booking_object",
        enable: true,
        permissions: [
          permissions.booking_object,
          permissions.booking_object.booking_object_add,
          permissions.booking_object.booking_object_edit,
          permissions.booking_object.booking_object_deleted,
          permissions.booking_object.booking_object_import,
          permissions.booking_object.booking_object_export,
        ],
        url: pathUrl.BOOKING_OBJECT.INDEX,
      },
      {
        label: "lbl_menu_service",
        enable: true,
        permissions: [
          permissions.service,
          permissions.service.service_add,
          permissions.service.service_edit,
          permissions.service.service_deleted,
          permissions.service.service_import,
          permissions.service.service_export,
        ],
        url: pathUrl.SERVICE.INDEX,
      },
    ]
  },

  {
    label: "lbl_menu_setting",
    enable: true,
    permissions: [
      permissions.config,
    ],
    icon: <SettingOutlined />,
    children: [
      {
        label: "lbl_menu_config",
        enable: true,
        permissions: [
          permissions.config,
          permissions.config.config_add,
          permissions.config.config_edit,
          permissions.config.config_deleted,
          permissions.config.config_import,
          permissions.config.config_export,
        ],
        url: pathUrl.CONFIG.INDEX,
      },
      {
        label: "lbl_menu_notification",
        enable: true,
        permissions: [
          permissions.notification,
          permissions.notification.notification_add,
          permissions.notification.notification_edit,
          permissions.notification.notification_deleted,
          permissions.notification.notification_import,
          permissions.notification.notification_export,
        ],
        url: pathUrl.NOTIFICATION.INDEX,
      },

    ]
  },

  {
    label: "lbl_menu_tracking",
    enable: true,
    permissions: [
      permissions.config,
    ],
    icon: <RadarChartOutlined />,
    children: [
      {
        label: "lbl_menu_tracking_request",
        enable: true,
        permissions: [
          permissions.tracking_request,
          permissions.tracking_request.tracking_request_add,
          permissions.tracking_request.tracking_request_edit,
          permissions.tracking_request.tracking_request_deleted,
          permissions.tracking_request.tracking_request_import,
          permissions.tracking_request.tracking_request_export,
        ],
        url: pathUrl.TRACKING_REQUEST.INDEX,
      },
    ]
  },

]



